import { BrowserRouter, Routes, Route } from "react-router-dom";
import StudentCert from "./componets/StudentCert";
// import Students from "./pages/students/Students";
import Home from "./pages/home/Home";
import NotFound from "./componets/NotFound";
// import StudentCert from "./componets/studentCert";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route
              index
              path="students/:student_id/certificate/:certificate_id"
              element={<StudentCert />}
            />
            <Route path="*" element={<NotFound />} />
          </Route>

          {/* <Route path="students"> */}
          {/* <Route index element={<Students />} /> */}
          {/* <Route
            index
            path="students/:student_id/certificate/:certificate_id"
            element={<StudentCert />}
          /> */}
          {/* </Route> */}
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
