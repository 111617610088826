import { useParams } from "react-router-dom";
import "../styles/certificate.css";
import Female from "../assets/images/female.jpeg";
import Male from "../assets/images/male.jpeg";
import { useGetCertDetailsQuery } from "../services/appApi";

const StudentCert = () => {
  const { student_id, certificate_id } = useParams();
  const { data, error, isLoading } = useGetCertDetailsQuery({
    student_id,
    certificate_id,
  });

  let image = "";

  if (data) {
    image = data.gender === "Female" ? Female : Male;
  }

  return (
    <div className="cert-container">
      {error ? (
        <>Oh no, there was an error</>
      ) : isLoading ? (
        <>Loading...</>
      ) : data ? (
        <>
          <div className="cert-convas">
            <div
              className="cert-image"
              style={{
                backgroundColor: `${
                  data.gender === "Female" ? "#EFC3CA" : "#E2EAF4"
                }`,
              }}
            >
              <img src={data.url ? data.url : image} alt="student" />
            </div>
            <div className="cert-data">
              <h1 style={{ paddingLeft: "10px" }}>{data.name}</h1>

              <div className="cert-course-details">
                <p>
                  <span>Student ID:</span> {student_id}
                </p>
                <p>
                  <span>Course: </span>
                  {data.course}
                </p>

                <p>
                  <span>Duration:</span> {data.duration}
                </p>
              </div>

              <div className="cert-school-tag">
                <p>Castush Academy</p>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default StudentCert;
