import error404 from "../assets/images/error-404.png";

const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <img src={error404} alt="error 404" width="50px" height="50px" />
        <p> Page Not Found</p>
      </div>
    </div>
  );
};

export default NotFound;
